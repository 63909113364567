




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getRemoteUsers, getOfficesLight } from '@/api/helpers'
import { getTranslations, validateForm } from '@/utils'
import { Form } from 'element-ui'

@Component({
  name: 'DetailsCard'
})

export default class extends Vue {
  @Prop({ required: true }) private recommendation: any
  private usersLoading = false
  private users: any = []
  private offices = []
  private user: any = {}
  private getTranslations = getTranslations
  private atLeastOne = (rule: any, value: string, callback: Function) => {
    const isValid = this.recommendation.user_id_to || this.recommendation.office_id_to || null
    return isValid ? callback() : callback(this.$t('form.userOrOfficeRequired'))
  }

  private rules = {
    user_id_to: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }],
    office_id_to: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }]
  }

  created() {
    if (this.recommendation.user_to) {
      this.users = [this.recommendation.user_to]
      this.user = this.recommendation.user_to
    }
    getOfficesLight({
      query: {
        pagination: {
          fetch_all: true
        }
      }
    }).then(({ data }) => {
      this.offices = data.collection
    })
  }

  private async getRemoteUsersList(query: string) {
    this.usersLoading = true
    this.users = await getRemoteUsers(query, [], {
      options: {
        all_users: true
      }
    })
    this.usersLoading = false
  }

  private onSelectChange() {
    if (this.recommendation.meta.client) {
      this.recommendation.meta.client.user_id = this.recommendation.user_id_to
      this.recommendation.meta.client.office_id = this.recommendation.office_id_to
    }
    if (this.recommendation.type === 'property' && this.recommendation.meta.property) {
      this.recommendation.meta.property.user_id = this.recommendation.user_id_to
      this.recommendation.meta.property.office_id = this.recommendation.office_id_to
    }
    validateForm(this.$refs.detailsForm as Form)
  }

  private onUserSelectChange() {
    if (this.user) {
      this.recommendation.user_id_to = this.user.id
      this.recommendation.office_id_to = this.user.office_id
    } else {
      this.recommendation.user_id_to = null
    }
    this.onSelectChange()
  }

  private onOfficeSelectChange() {
    this.user = null
    this.recommendation.user_id_to = null
    this.onSelectChange()
  }
}
